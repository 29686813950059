<template>
  <div class="py-1">
    <div v-if="loading" class="spinner-overlay">
      <b-spinner variant="primary" label="Loading..."></b-spinner>
    </div>
    <b-card no-body>
      <b-card-header>
        <div>
          <h4 class="font-weight-bolder">Update Lecture</h4>
          <p>You can update lecture here for OXAR teacher.</p>
        </div>
        <div>
          <b-button
            type="submit"
            variant="colorLightBlue"
            rounded
            class="mr-1"
            @click="addLecture()"
          >
            <span class="text-colorBlue">Cancel</span>
          </b-button>
          <b-button type="submit" variant="colorBlue" rounded>
            <span class="text-colorWhite">Update Lecture</span>
          </b-button>
        </div>
      </b-card-header>
      <b-card-body>
        <hr />
        <b-form prevent.default>
          <b-form-group class="py-1 mb-0">
            <b-form-checkbox v-model="first_lecture" id="first-lecture" name="first-lecture">
              <span class="font-weight-bolder">First Lecture</span>
            </b-form-checkbox>
          </b-form-group>
          <hr />
          <b-form-row class="py-1">
            <b-col>
              <span class="font-weight-bolder">Class Name*</span>
            </b-col>
            <b-col>
              <b-form-group class="mb-0">
                <v-select
                  label="name"
                  v-model="classe"
                  placeholder="Select Class"
                  :options="classes"
                  autoscroll
                  :clearable="true"
                ></v-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
          <b-form-row class="py-1">
            <b-col>
              <span class="font-weight-bolder">Subject*</span>
            </b-col>
            <b-col>
              <b-form-group class="mb-0">
                <v-select
                  label="name"
                  v-model="subject"
                  placeholder="Select Subject"
                  :options="subjects"
                  autoscroll
                  :clearable="true"
                ></v-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
          <b-form-row class="py-1">
            <b-col>
              <span class="font-weight-bolder">Lecture Title*</span>
            </b-col>
            <b-col>
              <b-form-group class="mb-0">
                <b-form-input id="lectureTitle" placeholder="Lecture Title" v-model="title" />
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
          <b-form-row class="py-1">
            <b-col>
              <span class="font-weight-bolder">Lesson Number*</span>
            </b-col>
            <b-col>
              <b-form-group class="mb-0">
                <b-form-input id="lectureTitle" v-model="lesson_number" placeholder="Lesson Number" />
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
          <b-form-row class="py-1">
            <b-col>
              <span class="font-weight-bolder">Lecture Duration*</span>
            </b-col>
            <b-col>
              <b-form-group class="mb-0">
                <b-form-input id="lectureTitle" v-model="duration" placeholder="Add Minutes" />
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
          <b-form-row class="py-1">
            <b-col>
              <span class="font-weight-bolder">Key Concepts*</span>
            </b-col>
            <b-col>
              <b-form-group class="mb-0">
                <div class="tags-input">
                  <span v-for="(tag, index) in key_concepts" :key="index" class="tag">
                    {{ tag }}
                    <button @click.prevent="removeTag(index)">x</button>
                  </span>
                  <input
                    type="text"
                    class="newTagClass"
                    v-model="newTag"
                    @keyup.enter="addTag"
                    @keyup="checkForComma"
                    placeholder="Add a tag"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
          <b-form-group class="py-1 mb-0">
            <b-form-checkbox
              id="add-lesson-content"
              name="add-lesson-content"
              v-model="addLessonContent"
            >
              <span class="font-weight-bolder">Add Lesson Content</span>
            </b-form-checkbox>
          </b-form-group>
          <b-form-group class="mt-2" v-if="addLessonContent">
            <b-form-textarea v-model="lesson_content"  />
          </b-form-group>
          <b-form-group class="py-1 mb-0">
            <b-form-checkbox
              id="include-real-life"
              name="include-real-life"
              v-model="includeRealLife"
            >
              <span class="font-weight-bolder">Include real-life examples</span>
            </b-form-checkbox>
          </b-form-group>
          <b-form-group class="mt-2" v-if="includeRealLife">
            <quill-editor v-model="example"  />
          </b-form-group>
          <b-form-group class="py-1 mb-0">
            <b-form-checkbox
              id="include-case-studies"
              name="include-case-studies"
              v-model="includeCaseStudies"
            >
              <span class="font-weight-bolder">Include case studies</span>
            </b-form-checkbox>
          </b-form-group>
          <b-form-group class="mt-2" v-if="includeCaseStudies">
            <quill-editor v-model="case_studies"  />
          </b-form-group>
          <b-form-group class="py-1 mb-0">
            <b-form-checkbox
              id="add-exercise-content"
              name="add-exercise-content"
              v-model="addExerciseContent"
            >
              <span class="font-weight-bolder">Add Exercise content</span>
            </b-form-checkbox>
          </b-form-group>
          <b-form-group class="mt-2" v-if="addExerciseContent">
            <b-form-row class="py-1">
              <b-col>
                <span class="font-weight-bolder">Exercise Placement*</span>
              </b-col>
              <b-col>
                <b-form-group class="mb-0">
                  <v-select
                    :options="['Math', 'English']"
                    placeholder="Exercise Placement"
                    v-model="excercise_placement"
                  />
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row class="py-1">
              <b-col>
                <span class="font-weight-bolder"
                  >Exercise And Activity Duration*</span
                >
              </b-col>
              <b-col>
                <b-form-group class="mb-0">
                  <v-select
                    :options="['Math', 'English']"
                    placeholder="Exercise And Activity  Duration"
                    v-model="exercise_duration"
                  />
                </b-form-group>
              </b-col>
            </b-form-row>
            <quill-editor v-model="exercise_description"   class="py-1" />
            
          </b-form-group>
        </b-form>
      </b-card-body>
      <b-card-footer footer-class="d-flex justify-content-end">
        <div>
          <b-button
            type="submit"
            variant="colorLightBlue"
            rounded
            class="mr-1"
            @click="
              () => {
                this.$router.push({ name: 'InstructorDashboard' });
              }
            "
          >
            <span class="text-colorBlue">Cancel</span>
          </b-button>
          <b-button
            type="submit"
            variant="colorBlue"
            rounded
            @click="addLecture()"
          >
            <span class="text-colorWhite">Update Lecture</span>
          </b-button>
        </div>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import { mapActions, mapGetters } from "vuex";
import axios from 'axios';


export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      option: [],
      addLessonContent: false,
      includeRealLife: false,
      includeCaseStudies: false,
      addExerciseContent: false,
      first_lecture : false,
      classe : "",
      subject : "",
      title : "",
      lesson_number : "",
      duration : "",
      key_concepts : [],
      lesson_content : "",
      example : "",
      case_studies : "",
      excercise_placement : "",
      exercise_duration : "",
      exercise_description : "",
      newTag: "",
      classes: [],
      subjects: [],
      loading: false,
      lectureData: {},
    };
  },
  methods: {
    ...mapActions({
      updateLecture : "appData/updateLecture",
      getClassDropDown: "appData/getClassDropDown",
      getSubjectDropDown: "appData/getSubjectDropDown",
      clearLectureData: "appData/clearLectureData",
    }),

    addTag() {
      const trimmedTag = this.newTag.trim();
      if (trimmedTag && !this.key_concepts.includes(trimmedTag)) {
        this.key_concepts.push(trimmedTag); // Push tag to the array
        this.newTag = ""; // Clear input after adding the tag
      }
    },
    checkForComma() {
      // Automatically add tag if comma is detected
      if (this.newTag.includes(",")) {
        this.newTag = this.newTag.replace(/,/g, "").trim();
        this.addTag();
      }
    },
    removeTag(index) {
      this.key_concepts.splice(index, 1); // Remove tag by index
    },
    async addLecture() {
      try {
        this.loading = true;
        
        // Prepare the FormData object
        const formData = new FormData();
        formData.append("first_lecture", this.first_lecture);
        formData.append("classe", this.classe.id);
        formData.append("subject", this.subject.id);
        formData.append("title", this.title);
        formData.append("lesson_number", this.lesson_number);
        formData.append("duration", this.duration);
        formData.append("address", this.address);
        formData.append("key_concepts", this.key_concepts);
        formData.append("lesson_content", this.lesson_content);
        formData.append("example", this.example);
        formData.append("case_studies", this.case_studies);
        formData.append("excercise_placement", this.excercise_placement);
        formData.append("exercise_duration", this.exercise_duration);
        formData.append("exercise_description", this.exercise_description);

        formData.append("updated_by", this.getLoggedInUser.id); 

        // Create the lecture
        const res = await this.updateLecture({
          payload: formData,
          pk: this.lectureData.id
        });
        if (res.status === 200) {
          this.$swal({
            title: "Lecture Updated successfully",
            icon: "success",
          });
          this.$router.push({ name: "InstructorDashboard" });
        }
      } catch (error) {
        console.error('Error:', error);
        this.displayError(error);
      } finally {
        this.loading = false;  // Hide spinner
      }
    },

  },
  
  async mounted(){
    const res_classes = await this.getClassDropDown();
    this.classes = res_classes.data;

    const res_subject = await this.getSubjectDropDown();
    this.subjects = res_subject.data;
    this.lectureData = this.$store.state.appData.lectureData;
    if(this.lectureData){
      this.first_lecture = this.lectureData.first_lecture;
      this.classe = this.classes.find(classe => classe.id === this.lectureData.classe);
      this.subject = this.subjects.find(subject => subject.id === this.lectureData.subject);
      this.title = this.lectureData.title;
      this.lesson_number = this.lectureData.lesson_number;
      this.duration = this.lectureData.duration;
      this.address = this.lectureData.address;
      if (typeof this.lectureData.key_concepts === 'string') {
        this.key_concepts = this.lectureData.key_concepts.split(',');
      } else {
        this.key_concepts = this.lectureData.key_concepts || [];
      }
      this.lesson_content = this.lectureData.lesson_content;
      this.example = this.lectureData.example;
      this.case_studies = this.lectureData.case_studies;
      this.excercise_placement = this.lectureData.excercise_placement;
      this.exercise_duration = this.lectureData.exercise_duration;
      this.exercise_description = this.lectureData.exercise_duration;

      this.addLessonContent = !!this.lesson_content;
      this.includeRealLife = !!this.example;
      this.includeCaseStudies = !!this.case_studies;
      this.addExerciseContent = !!this.excercise_placement;

    }
  },

  beforeUnmount() {
    this.clearLectureData(); // Clear lecture data before unmounting
  },
  
  computed: {
    ...mapGetters({
      getLoggedInUser: "appData/getLoggedInUser"
    }),
  },
  
  beforeRouteLeave(to, from, next) {
    this.clearLectureData();
    next();
  },
  
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/quill.scss";

.tags-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
}

.tag {
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
}

.tag button {
  background: transparent;
  border: none;
  color: white;
  margin-left: 5px;
  cursor: pointer;
  font-size: 12px;
}

.newTagClass {
  border: none;
  outline: none;
  flex: 1;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* White background with opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* To ensure it's on top of everything */
}

</style>
